import React from "react";

import check from "../assets/check.png";

const OnSuccessComponent = () => {
  return (
    <div>
      <div className="text-center mb-5">
        <img src={check} className="check" />
      </div>
      <h2 className="mb-3 text-center">
        Thank you for enrolling your loved one into Rubix Healthcare
      </h2>
      <p className="text-center">
        We will take it from here. Someone will be with your loved one within
        the week to complete the signup process.
      </p>
    </div>
  );
};

export default OnSuccessComponent;
