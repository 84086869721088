import "./App.css";
import React, { useState } from "react";

// Third Party Libraries
import { Form, Field } from "react-final-form";
import { Form as FormReact } from "reactstrap";

// Firebase
import { db } from "./firebase-config";
import { collection, getDocs, addDoc, Timestamp } from "firebase/firestore";

// Assets
import logo from "./assets/logo.jpeg";

// Components
import OnSuccessComponent from "./components/onSuccessComponent";

function App() {
  const [onSuccess, setSuccess] = useState(false);
  const enrollmentsCollectionRef = collection(db, "enrollments");

  const createEnrollment = async (data) => {
    data.createdAt = Timestamp.now().toDate().toString();

    await addDoc(enrollmentsCollectionRef, data)
      .then(() => {
        console.log("Successfully Added to Collection!");
        setSuccess(true);
      })
      .catch((error) => console.log("Error Adding: " + error));
  };

  return (
    <div className="App">
      <div className="container-sm mb-5">
        <div className="mt-5 me-1 ms-1 outerbox">
          <div className="mb-5">
            <img src={logo} className="logo mb-5"></img>
            <h1 className="text-center fw-light">New Patient Enrollment</h1>
          </div>

          {onSuccess === true ? (
            <OnSuccessComponent />
          ) : (
            <div className="">
              <Form
                onSubmit={(dataObj) => {
                  createEnrollment(dataObj);
                }}
              >
                {({ handleSubmit }) => (
                  <FormReact onSubmit={handleSubmit} className="">
                    <h5 className="mt-4">Patients Full Name</h5>

                    <Field name="patient_name">
                      {({ input }) => (
                        <input
                          className="mt-2 p-2"
                          style={{ width: "100%" }}
                          placeholder="Ex. Maddison A. Jane"
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>

                    <h5 className="mt-4">Your Relation to Patient</h5>

                    <Field name="relation">
                      {({ input }) => (
                        <input
                          className="mt-2 p-2"
                          style={{ width: "100%" }}
                          placeholder="Ex. Son (Leave blank if you're the patient)"
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>

                    <h5 className="mt-3">Your Full Name</h5>

                    <Field name="name">
                      {({ input }) => (
                        <input
                          className="mt-2 p-2"
                          style={{ width: "100%" }}
                          placeholder="Leave blank if you are the patient"
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>

                    <h5 className="mt-4">Building / Residence Name</h5>

                    <Field name="building">
                      {({ input }) => (
                        <input
                          className="mt-2 p-2"
                          style={{ width: "100%" }}
                          placeholder="Ex. Brookfield"
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>

                    <h5 className="mt-4">Your Phone Number</h5>

                    <Field name="phone">
                      {({ input }) => (
                        <input
                          className="mt-2 p-2"
                          style={{ width: "100%" }}
                          placeholder=""
                          type="phone"
                          {...input}
                        />
                      )}
                    </Field>

                    <div className="d-grid">
                      <button className="btn btn-primary mt-5" type="submit">
                        Submit
                      </button>
                    </div>
                  </FormReact>
                )}
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
